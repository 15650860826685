import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["data-list"]
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = { class: "fixed-footer-content__footer" }

import type { ComputedRef, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import router from '@/features/core/router';
import { useOrder, useOverpickingThresholds } from '@/features/picking';
import {
  AppHeader,
  Button,
  reviewFilters,
  ScreenLoading,
  StatusBar,
  TabVariants,
} from '@/features/ui';
import {
  OrderEventNames,
  ordersServicePlugin,
  useAggregateOrderItems,
} from '@/features/orders';
import { CancelPickingConfirmationPopup, ReviewCard } from '../components';
import RowFilter from '@/features/ui/components/RowFilter.vue';
import { isCancellableOrder } from '@/utils/helpers/isCancellableOrder';
import { partitionReviewItems } from '../helpers';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { cancelOrder } from '@/features/handover/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReviewOrder',
  props: {
  id: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const {
  order,
  ordersOnTab,
  currentTab,
  quantity,
  filterQuantity,
  loading,
  loadOrder,
  onChangeTab,
  redirectIfOrderNotPicking,
} = useOrder();
const cancellationInProcess = ref(false);
const processing = ref(false);
const overpickingThreshold = useOverpickingThresholds();
const { startTracking, stopTracking } = usePerformanceTracker();

const isOrderCancellable: ComputedRef<boolean> = computed(() =>
  isCancellableOrder(order.value, quantity.value),
);

onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`picking-order-to-review-order-${props.id}`);
  await redirectIfOrderNotPicking(order.value);
  currentTab.value = TabVariants.All;
  if (isOrderCancellable.value) {
    await goNext();
  }
});

const isCancelPickingConfirmationPopupVisible: Ref<boolean> = ref(false);

const onConfirmCancellationPicking = async () => {
  if (cancellationInProcess.value) {
    return;
  }

  if (order.value === null) {
    return;
  }

  try {
    processing.value = true;
    isCancelPickingConfirmationPopupVisible.value = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await cancelOrder(order.value, cancellationInProcess);
  } finally {
    processing.value = false;
  }
};

const onDeclineCancellationPicking = () => {
  isCancelPickingConfirmationPopupVisible.value = false;
};

const goNext = async () => {
  if (isOrderCancellable.value) {
    isCancelPickingConfirmationPopupVisible.value = true;

    return;
  }

  processing.value = true;

  startTracking(
    `review-order-to-order-bags-${props.id}`,
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );
  if (order.value) {
    await ordersServicePlugin
      .get()
      .trackEvent(order.value, OrderEventNames.picking_completed);
  }
  await router.get().push({
    name: 'order-bags',
    params: { id: props.id },
  });
};

const { aggregatedOrderItems } = useAggregateOrderItems(ordersOnTab);

const orderItems = computed(() =>
  partitionReviewItems(aggregatedOrderItems.value, overpickingThreshold.value),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_unref(StatusBar), {
              customerNote: _unref(order)?.cartNote,
              order: _unref(order),
              to: { name: 'picking-order', params: { id: _unref(order).id } }
            }, null, 8, ["customerNote", "order", "to"]),
            _createVNode(RowFilter, {
              "current-tab": _unref(currentTab),
              filterQuantity: _unref(filterQuantity),
              filters: _unref(reviewFilters),
              onChangeTab: _unref(onChangeTab)
            }, null, 8, ["current-tab", "filterQuantity", "filters", "onChangeTab"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading) || processing.value)
      ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
          key: 1,
          title: 
      cancellationInProcess.value
        ? _ctx.$t('pages.review-order.loading-title-cancel-order')
        : _ctx.$t('pages.review-order.loading-title-save-order')
    ,
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderItems.value, (filteredOrder, key) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
          (filteredOrder.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                "data-list": key,
                class: "list"
              }, [
                (_unref(currentTab) === _unref(TabVariants).All)
                  ? (_openBlock(), _createElementBlock("h3", {
                      key: 0,
                      "data-e2e": `review-order-${key}-title`,
                      class: "title"
                    }, _toDisplayString(key), 9, _hoisted_3))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOrder, (orderItem) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: orderItem.id,
                    class: "card"
                  }, [
                    _createVNode(_unref(ReviewCard), {
                      disabled: !orderItem.quantity,
                      orderItem: orderItem,
                      class: "card--original"
                    }, null, 8, ["disabled", "orderItem"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderItem.replacements, (replacementItem) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: replacementItem.id
                      }, [
                        _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "separator" }, null, -1)),
                        _createVNode(_unref(ReviewCard), {
                          isQuantityOriginalDisplayed: false,
                          orderItem: replacementItem,
                          class: "card--replacement"
                        }, null, 8, ["orderItem"])
                      ], 64))
                    }), 128))
                  ]))
                }), 128))
              ], 8, _hoisted_2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _createElementVNode("footer", _hoisted_4, [
      _createVNode(_unref(Button), {
        disabled: processing.value,
        btnOutline: "",
        "data-e2e": "finish-picking-button",
        onClick: goNext
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('pages.review-order.finish-picking-button.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _createVNode(_unref(CancelPickingConfirmationPopup), {
      isVisible: isCancelPickingConfirmationPopupVisible.value,
      onCancel: onDeclineCancellationPicking,
      onConfirm: onConfirmCancellationPicking
    }, null, 8, ["isVisible"])
  ], 64))
}
}

})